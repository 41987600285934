import React from "react";
import "./App.css";
import Editor from "./Editor";
import Header from "./Header";
import Viewer from "./Threekit";
import { getInitParams } from "./Utilities";

export default function App() {
  const { assetId, authToken } = getInitParams();

  return (
    <div className="app">
      <Header />
      <div className="content">
        {assetId && authToken ? <Viewer /> : <Editor />}
      </div>
    </div>
  );
}
