import deepmerge from "deepmerge";
import { buildNestedValue } from "../Utilities";

const UPDATE = "update";

export function reducer(state = {}, { type, ...payload }) {
  switch (type) {
    case UPDATE:
      return deepmerge.all([
        state,
        buildNestedValue(payload.value, payload.path),
      ]);

    default:
      return state;
  }
}

export const setValue = (path, value) => ({ type: UPDATE, path, value });
