import React from "react";
import { setValue } from "./state";

export function Form({ dispatch, state }) {
  return (
    <div className="form widget">
      <Field
        dispatch={dispatch}
        label="Auth Token"
        path="authToken"
        required={true}
        type="uuid"
        value={state.authToken}
      />
      <Field
        dispatch={dispatch}
        label="Asset Id"
        path="assetId"
        required={true}
        type="uuid"
        value={state.assetId}
      />
      <Field
        dispatch={dispatch}
        label="Org Id"
        path="orgId"
        type="uuid"
        value={state.orgId}
      />
      <Field
        dispatch={dispatch}
        label="Stage Id"
        path="stageId"
        type="uuid"
        value={state.stageId}
      />
      <Field
        dispatch={dispatch}
        label="Environment"
        path="environment"
        type="enum"
        value={state.environment}
      />
      <Field
        dispatch={dispatch}
        label="Display"
        path="display"
        type="enum"
        value={state.display}
      />

      <Field
        dispatch={dispatch}
        label="Show AR"
        path="showAR"
        type="bool"
        value={state.showAR}
      />
      <Field
        dispatch={dispatch}
        label="Show Configurator"
        path="showConfigurator"
        type="bool"
        value={state.showConfigurator}
      />
      <Field
        dispatch={dispatch}
        label="Show Share"
        path="showShare"
        type="bool"
        value={state.showShare}
      />
    </div>
  );
}

function Field({ dispatch, label, path, required, type, value }) {
  function isValid() {
    if (type === "uuid") {
      if (required || !!value)
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
          value
        );

      return true;
    }

    return true;
  }

  return (
    <div className={`field ${isValid() ? "" : "invalid"}`}>
      <label>{label}</label>
      {type === "uuid" && (
        <input
          onChange={(ev) => {
            dispatch(setValue([path], ev.target.value?.trim() || null));
          }}
          type="text"
          value={value || ""}
        />
      )}

      {type === "enum" && (
        <input
          onChange={(ev) => {
            dispatch(setValue([path], ev.target.value?.trim() || null));
          }}
          type="text"
          value={value || ""}
        />
      )}

      {type === "bool" && (
        <input
          onChange={(ev) => {
            dispatch(setValue([path], ev.target.checked));
          }}
          type="checkbox"
          value={value || ""}
        />
      )}
    </div>
  );
}
