import { useEffect, useState } from "react";

export function getEnvironment() {
  const searchEntities = [...new URLSearchParams(window.location.search)];
  const environmentEntity = searchEntities.find(([qKey]) =>
    /environment/gi.test(qKey)
  );
  return environmentEntity ? environmentEntity[1] : "preview";
}

export const properKeys = [
  "authToken",
  "assetId",
  "display",
  "orgId",
  "stageId",
  "showAR",
  "showConfigurator",
  "showShare",
];

export function getInitParams() {
  const searchEntities = [...new URLSearchParams(window.location.search)];

  return properKeys.reduce((acc, properKey) => {
    const searchEntry = searchEntities.find(([qKey]) =>
      new RegExp(properKey, "gi").test(qKey)
    );

    return searchEntry
      ? { ...acc, [properKey]: parseInit(searchEntry[1]) }
      : acc;
  }, {});
}

function parseInit(value) {
  if (/^true$/gi.test(value)) return true;
  if (/^false$/gi.test(value)) return false;

  return value;
}

export function buildNestedValue(value, path) {
  return path.reduceRight(
    (acc, cur, index) =>
      path.length - 1 <= index ? { [cur]: value } : { [cur]: acc },
    {}
  );
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
