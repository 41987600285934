import React, { useCallback, useState } from "react";
import { getEnvironment, getInitParams } from "../Utilities";
import "./style.css";

export default function Viewer() {
  const playerRef = usePlayer();

  return <div className="player" ref={playerRef} />;
}

export function usePlayer(callback) {
  const [isLoaded, setIsLoaded] = useState(false);

  const playerRef = useCallback(
    async (el) => {
      if (!el || isLoaded) return;
      setIsLoaded(true);

      await loadPlayerScript(getEnvironment());

      const player = await window.threekitPlayer({ ...getInitParams(), el });
      window.player = player;
      if (callback) callback(player);
      window.configurator = await player.getConfigurator();
    },
    [callback, isLoaded]
  );

  return playerRef;
}

function loadPlayerScript(environment) {
  if (!environment) throw Error("Environment not passed to loadPlayerScript");

  return new Promise((resolve, reject) => {
    if (window?.threekitconf?.threekitEnv)
      return reject(`threekitPlayer script already loaded`);

    const script = document.createElement("script");
    script.onload = function () {
      resolve("loaded");
    };

    script.src = `https://${environment}.threekit.com/app/js/threekit-player-bundle.js`;
    window.document.getElementsByTagName("head")[0].appendChild(script);
  });
}
