import QRCode from "qrcode.react";
import queryString from "query-string";
import React, { useReducer, useState } from "react";
import { getEnvironment, getInitParams, properKeys } from "../Utilities";
import { Form } from "./form";
import plus from "./plus.svg";
import { reducer } from "./state";
import "./style.css";

export default function Editor() {
  const [state, dispatch] = useReducer(reducer, {
    ...properKeys.reduce((acc, cur) => ({ ...acc, [cur]: null }), {}),
    ...getInitParams(),
    environment: getEnvironment(),
  });

  const root = `${window.location.origin}${window.location.pathname}`;
  const qStr = queryString.stringify(
    Object.entries(state)
      .filter(([key, val]) => val !== null)
      .reduce((a, [k, v]) => ({ ...a, [k]: v }), {})
  );

  const direct = `${root}?${qStr}`;

  const share = `https://share.demo.threekit.com/gh/tk-viewer?${qStr}`;

  console.log("===Editor State===\n", state);

  return (
    <div className="editor">
      <Form dispatch={dispatch} state={state} />
      <Link label="Direct Link" url={direct} />
      <Link label="Share Link" url={share} />
      <QR url={share} />
    </div>
  );
}

function Link({ url, label }) {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(null);

  function copy() {
    var textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setMsg(label);
    setTimeout(() => {
      setMsg(null);
    }, 2000);
  }

  return (
    <div className="links widget">
      <div>
        <img
          alt="open"
          className={open ? "toggled" : ""}
          onClick={() => setOpen(!open)}
          src={plus}
          height="20px"
          width="20px"
        />
        <label>{label}</label>{" "}
        <img
          alt="copy"
          height="15px"
          onClick={copy}
          src="https://i.imgur.com/6IJEKgS.png"
          width="15px"
        />
      </div>
      <div>{open ? url : null}</div>
      {msg && <div className="popup">Copied {msg}</div>}
    </div>
  );
}

function QR({ url }) {
  const [open, setOpen] = useState(false);

  return (
    <div className={`qr widget ${open ? "qr-open" : "qr-closed"}`}>
      <div>
        <img
          alt="open"
          className={open ? "toggled" : ""}
          onClick={() => setOpen(!open)}
          src={plus}
          height="20px"
          width="20px"
        />
        <label>QR Code</label>
      </div>
      <QRCode
        className={open ? "qr-open" : "qr-closed"}
        size={300}
        value={url}
      />
    </div>
  );
}
